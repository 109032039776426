<template>
  <div id="Connections">
    <div class="posFix" ref="posFix">
      <div class="titleTop" >
        <!-- <div @click="changePath('/FindOut')">问答</div> -->
        <div class="active">人脉圈</div>
        <div @click="changePath('/CompanyList')">公司库</div>
        <div @click="changePath('/ActivityLine?activeName=c')">线下活动</div>
        <div @click="changePath('/ActivityLine?activeName=d')">保观集市</div>
      </div>
      <van-search
        shape="round"
        background="#23252E"
        placeholder="请输入搜索关键词"
        @focus = focusChange
        style="padding:0.15rem"
        :readonly="true"
      />
      <div class="hotMain">
        <div class="forth">
          <div>保观大咖</div>
          <div>
            <span @click="show = !show;" :class="{'white':show}">
              <img v-if="!show" src="../assets/image/findOut/flitGray.png" />
              <img v-else src="../assets/image/findOut/flitWhite.png" />
              筛选</span>
            <span @click="show = false;checkMoreSort()" v-if="name != 'findConnections'">
              <span :class="{'white':letters && sort == 3}">活跃度排列</span>
            </span>
            <span @click="show = false;checkSort()" v-if="name != 'findConnections'">
              <span style="display:flex;flex-direction: column;">
                <van-icon :class="{'white':letters && sort == 1}" name="play" style="font-size:0.12rem;transform: rotate(-90deg);" />
                <van-icon :class="{'white':letters && sort == 2}" name="play" style="font-size:0.12rem;transform: rotate(90deg);margin-top:-0.05rem;margin-right:0.01rem" />
              </span>
              <span :class="{'white':letters && (sort == 1 ||sort == 2)}">字母排列</span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="blackFix" ref="blackFix"></div>
  
    <div  class="padTop" ref="topMost"></div>
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
        class="allList"
      >
        <div v-for="(i,item) in getDocList" :key="item">
          <van-index-anchor  v-show="(item == 0 || i.initial != getDocList[item-1].initial) && moreLetter" :index="i.initial" />
          <div class="list" @click="toIntroDetail(i.user.id,i.name)" >
            <div v-if="i.user">
              <img :src="i.user.headimgurl ? Global.changeImg(i.user.headimgurl) :'../assets/image/home/image1.png'"/>
              <div>
                <p class="first">{{i.name}}</p>
                <p class="second">{{i.company}}{{i.job}}</p>
                <p class="third">
                  <span v-if="i.city">{{i.city}}</span>
                  <span v-if="i.industry == 1" >保险</span>
                  <span v-if="i.industry == 2" >创业</span>
                  <span v-if="i.industry == 3" >互联网</span>
                  <span v-if="i.industry == 4" >观察者</span>
                  <span v-if="i.industry == 5" >投资</span>
                  <span v-if="i.industry == 6" >媒体</span>
                </p>
              </div>
            </div>
            <span v-if="i.user && name != 'findConnections'" @click.stop="toHello(i.user.id)">打个招呼</span>
          </div>
        </div>
      </van-list>
    <van-popup v-model="show" position="top"  @close="closeInd">
      <div class="topPop">
        <div class="titleTab">
          <div :class="{'active':industryId1 == ''}" @click="industryId1 = ''">全部</div>
          <div :class="{'active':industryId1 == 1}" @click="industryId1 = 1">保险</div>
          <div :class="{'active':industryId1 == 2}" @click="industryId1 = 2">创业</div>
          <div :class="{'active':industryId1 == 3}" @click="industryId1 = 3">互联网</div>
          <div :class="{'active':industryId1 == 4}" @click="industryId1 = 4">观察者</div>
          <div :class="{'active':industryId1 == 5}" @click="industryId1 = 5">投资</div>
          <div :class="{'active':industryId1 == 6}" @click="industryId1 = 6">媒体</div>
        </div>
        <div class="anscontain"><span @click="industryId1 = ''">重置</span><span @click="checkCateIdIndustry">完成</span></div>
      </div>
    </van-popup>

  </div>
</template>
<script>
export default {
  name: "Connections",
  data(){
    return{
      show:false,
      letters:false,
      industryId:'',//类别id
      industryId1:'',
      sort:'', //0 正常排序   1:字母正序排列  2:字母倒序排列
      loading:false,
      finished:false,
      getDocList:'',
      page:1,
      moreLetter:true,
      name:''
    }
  },
  mounted() {
    this.$refs.blackFix.style.top = this.$refs.posFix.offsetHeight + 'px'
    this.$refs.topMost.style.paddingTop = this.$refs.posFix.offsetHeight + this.$refs.blackFix.offsetHeight+'px'

    this.name = this.$route.name
    // console.log(this.$route)

    // 大咖列表
    this.axios({
      method: 'GET',
      url: '/master/getMasterList?sort=0&industryId=0&page=1',
      headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
      async:false
    }).then((res) => {
      if(res.data.code == 0){
        this.getDocList = res.data.data.data;
        this.page++
        this.moreLetter = false
      }
    })
  },
  methods:{
    // 排序选择
    checkSort(){
      window.scrollTo(0,0)
      this.show = false
        if(this.sort == 0 || this.sort == 3){
          this.sort = 1
          this.letters = true
          this.moreLetter = true
        }else if(this.sort == 1){
          this.sort = 2
          this.letters = true
          this.moreLetter = true
        }else{
          this.sort = 0
          this.letters = false
          this.moreLetter = false
        }
      
      this.firstLoad()
    },
    checkMoreSort(){
      this.sort = 3
      this.letters = true
      this.moreLetter = false
      this.firstLoad()
    },
    closeInd(){
      setTimeout(()=>{this.industryId1 = this.industryId},1000)
    },
    checkCateIdIndustry(){
      this.industryId = this.industryId1
      this.checkindustryId()
    },
    // 类别id选择
    checkindustryId(){
      this.show = false
      this.letters = false
      this.moreLetter = false
      if(this.sort == 3){
        this.sort = 3
      }else{
        this.sort = 0
      }
      
      this.firstLoad()
    },
    firstLoad(){
      window.scrollTo(0,0)
      this.page = 1;
      this.axios({
        method: 'GET',
        url: '/master/getMasterList?sort='+this.sort+'&industryId='+this.industryId+'&page='+this.page,
        headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
        async:false
      }).then((res) => {
        if(res.data.code == 0){
          this.loading = false
          this.finished = false
          if(res.data.data){
            this.getDocList = []
            this.getDocList = res.data.data.data;
          } 
          this.page++
          if(!res.data.data.data.length || !res.data.data){
            this.finished = true
          }
        }
      })
    },
    onLoad(){
      if(this.page!=1){
        var url;
        url = '/master/getMasterList?sort='+this.sort+'&industryId='+this.industryId+'&page='+this.page
        this.axios({
          method: 'GET',
          url: url,
          headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
          async:false
        }).then((res) => {
          if(res.data.code == 0){
            this.loading = false
            if(res.data.data){
              this.getDocList.push(...res.data.data.data)
            }
            this.page++
            if(!res.data.data.data.length || !res.data.data){
              this.finished = true
            } 
          }
        })
      }else{
        this.loading = false;
      }
    },
    // 点击搜索跳转
    focusChange(){
      this.$router.push({
        path: '/SearchFor',
      })
    },
    // 跳转页面
    changePath(path){
      this.$router.push({
        path: path,
      })
    },
    // 跳转大咖详情
    toIntroDetail(id,name){
      if(this.name != 'findConnections'){
        this.axios({
          method: 'PATCH',
          url: '/master/alwaysVisitAdd',
          headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
          data:{
            masterId:id
          }
        }).then((res) => {
          if(res.data.code == 0){
            this.$router.push({name:'Introduction',query: {id:id}})
            
          }
        })
      }else{
        this.$router.push({name:'Question',query: {id:id,name:name}})
      }
      
    },
    // 打招呼
    toHello(id){
      this.axios({
        method: 'POST',
        url: '/recognition/greetMaster',
        headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
        data:{
          masterId:id
        }
      }).then((res) => {
        if(res.data.code == 0){
          this.$toast(res.data.data.msg)          
        }
      })
    }
  }
};
</script>
<style scoped>
.personalContent{
  height: calc(100vh - 1.1rem);
}
</style>
<style lang="less" scoped>
#Connections {
  .blackFix{
    z-index: 3;
    background: #16171D;
    height: 0.11rem;
    position: fixed;
    width: 100%;
    max-width: 750px;
  }
  .posFix{
    position: fixed;
    top: 0;
    width: 100%;
    max-width: 750px;
    z-index: 10;
    background: #16171D;
  }
 
  .interests{
    display: flex;
    justify-content: space-between;
    >span{
      color: #989AB1;
      font-size: 0.14rem;
      display: flex;
      align-items: center;
      margin-right: 0.05rem;
      >img{
        width: 0.12rem;
        margin-right: 0.05rem;
      }
    }
  }
  .titleTop2{
    padding: 0.15rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #989AB1;
    font-size: 0.14rem;
    font-weight: 600;
    >span{
      color: #F6F7FD;
      font-size: 0.12rem;
    };
  }
  .titleTab{
    padding: 0.15rem 0.15rem 0.1rem;
    display: flex;
    flex-wrap: wrap;
    >div{
      display: inline-block;
      height: 0.25rem;
      border-radius: 0.25rem;
      padding: 0 0.12rem;
      line-height: 0.26rem;
      background: #3D404F;
      color: #989AB1;
      font-size: 0.12rem;
      margin-right: 0.12rem;
      margin-bottom: 0.1rem;
    }
    .active{
      background: #3890FF;
      color: #F6F7FD;
    }
  } 
  .topPop{
    .anscontain{
      display: flex;
      height: 0.4rem;
      border-top: 1px solid #5C5D6E61;
      >span{
        flex:1;
        text-align: center;
        line-height: 0.4rem;
        color: #989AB1;
        font-size: 0.14rem;
      }
      >span:nth-child(2){
        color: #3890FF;
        border-left: 1px solid #5C5D6E61;
      }
    }
  }
  .visit{
    overflow-x: scroll;
    padding-left: 0.15rem;
    white-space: nowrap;
    background: #23252E;
    >div{
      padding: 0.15rem 0;
      margin-right: 0.2rem;
      text-align: center;
      display: inline-block;
      >img{
        width: 0.51rem;
      }
      >span{
        color: #F6F7FD;
        font-size: 0.12rem;
        margin-top: 0.06rem;
        display: inline-block;
      }
    }
  }
  .littlietitle{
    color: #989AB1;
    height: 0.4rem;
    line-height: 0.4rem;
    padding: 0 0.15rem;
  }
  .allList{
    background: #23252E;
    >div:last-child{
      border-bottom: none;
    }
  }
  .list{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: .1px solid #5C5D6E61;
    margin: 0 0.15rem;
    padding: 0.13rem 0;
    >div{
      display: flex;
      align-items: center;
      flex: 1;
      padding-right: 0.1rem;
      >img{
        width: 0.6rem;
        height: 0.6rem;
        border-radius: 50%;
      }
      >div{
        padding: 0 0 0 0.15rem;
        position: relative;
        flex:1;
        .first{
          font-size: 0.16rem;
          color: #F6F7FD;
        }
        .third{
          display: flex;
          flex-wrap: wrap;
          >span{
            padding: 0 0.05rem;
            color: #9091A3;
            font-size: 0.12rem;
            border-radius: 0.02rem;
            background: #3D404F;
            margin: 0 0.05rem 0.05rem 0;
          }
        }
        .second{
          margin: 0.03rem 0 0.03rem 0;
          color: #989AB1;
          display: flex;
          font-size: 0.14rem;
          >span{
            display: flex;
            margin-left: 0.1rem;
            color: #989AB1;
          }
        }
      }
    }
    >span{
      background: #3890FF;
      color: #F6F7FD;
      display: inline-block;
      height: 0.25rem;
      border-radius: 0.25rem;
      padding: 0 0.12rem;
      line-height: 0.26rem;
      font-size: 0.12rem;
    }
  }
  .personalContent{
    overflow: auto;
    position: fixed;
    width: 100%;
    z-index: 0;
  }
  .hotMain{
    background: #23252E;
    .forth{
      display: flex;
      justify-content: space-between;
      padding: 0 0.15rem 0.15rem;
        >div:last-child{
          display: flex;
          >span{
            margin-left: 0.2rem;
            color: #727387;
            display: flex;
            align-items: center;
            font-size: 0.12rem;
            >img{
              width: 0.12rem;
              margin:0.02rem 0.05rem 0 0.13rem;
            }
          }
          .white{
            color: #F6F7FD;
          }
        }
        >div:first-child{
          color: #F6F7FD;
          font-size: 0.16rem;
        }
      }
  }
  .titleTop {
    background: #23252E;
    padding: 0.15rem;
    display: flex;
    align-items: center;
    font-size: 0.14rem;
    border-bottom: .1px solid #5C5D6E61;
    color: #989ab1;
    > div {
      margin-right: 0.2rem;
      position: relative;
    }
    .active {
      font-weight: 600;
      color: #f6f7fd;
      font-size: 0.14rem;
    }
    .active::after {
      position: absolute;
      content: "";
      height: 2px;
      background: #3890ff;
      left: 0;
      right: 0;
      margin: auto 0.1rem;
      bottom: -0.05rem;
    }
  }
  >div{
    background: #23252E;
  }
}
</style>